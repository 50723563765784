import React from 'react';
import styled from 'styled-components';
import { utils } from 'herald-fe-shared';
import { Router } from '@reach/router';

import Page from '~/layout/LayoutMarketing';
import Image from '~/components/Image';
import LargeCTA from '~/components/LargeCTA';
import Opener from '~/components/works-with/Opener';
import UseCaseSection, {
  UseCaseScreenshot,
} from '~/components/use-cases/UseCaseSection';
import BenefitsOfHerald from '~/components/works-with/BenefitsOfHerald';
import HeraldSolution from '~/components/works-with/HeraldSolution';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
  }
  .container__text {
    flex: 0 0 50%;
  }
  .text-medium {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .screenshots {
    position: relative;
    text-align: center;
  }
  .screenshots__wide {
    position: absolute;
    width: 60%;
    left: 0;
    top: 0;
  }
  .screenshots__narrow {
    width: 40%;
    margin: auto;
  }
  @media screen and (max-width: 600px) {
    .screenshots__wide {
      display: none;
    }
    .screenshots__narrow {
      position: absolute;
      width: 100%;
      right: 0%;
      top: 0;
    }
  }
`;

const WorksWithOtherApps: any = (props) => {
  const app = utils.strings.capitalize(props.app || '');
  return (
    <Page title="Herald consolidates customer feedback from all apps">
      <Styled>
        <Opener app={app} />
        <HeraldSolution
          firstSentence={
            <>
              Herald is the best tool to organize and quantify open-ended
              customer requests from {app} and other sources.
            </>
          }
        />
        <UseCaseSection
          orientation="center"
          title={
            <span className="text-medium">
              Herald's Browser Extension allows your team to tag customer
              conversations right from {app} with a couple of clicks.
            </span>
          }
          contentStyle={{ height: '20rem', maxWidth: 'none' }}
          textStyle={{ maxWidth: '32rem', lineHeight: '1.6rem' }}
          content={
            <div className="screenshots">
              <UseCaseScreenshot className="screenshots__narrow">
                <Image
                  src="screenshots/chrome-extension.png"
                  style={{ objectFit: 'contain', objectPosition: 'top center' }}
                />
              </UseCaseScreenshot>
            </div>
          }
        />
        <BenefitsOfHerald />
        <LargeCTA
          title={
            <>
              Ready to supercharge your team's{' '}
              <span style={{ textTransform: 'capitalize' }}>{app}</span>{' '}
              workflow?
            </>
          }
        />
      </Styled>
    </Page>
  );
};

const WorksWithOthersRouter: React.FC = () => {
  return (
    <Router>
      <WorksWithOtherApps path="/works-with/other/:app" />
      <WorksWithOtherApps path="/works-with/other" />
    </Router>
  );
};

export default WorksWithOthersRouter;
